<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2 col-lg-4 offset-lg-4">
        <b-card header="Zoeken">
          <b-form inline @submit.prevent="doSearch">
            <div style="width: 100%">
              <b-input-group>
                <b-input type="text" v-model="query" />
                <b-input-group-append>
                  <b-button type="submit" :disabled="loading">
                    <b-icon-search/>
                    Zoeken
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <span class="text-danger small" v-if="noResults">Geen resultaten gevonden.</span>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <b-card :header="events.length +' resultaten'" class="mt-3" no-body v-if="events.length > 0">
          <b-list-group flush>
            <b-list-group-item v-for="event in events" :key="event.id">
            <span class="float-right small text-muted pt-1 text-right">
              {{ getWeekday(event.starttime) }} {{ formatDate(event.starttime) }}
              <br />
              {{ formatTime(event.starttime) }}
              &ndash;
              {{ formatTime(event.endtime) }}
            </span>
              <router-link :to="{name: 'Event', params: { id: event.id }}">{{ event.name }}</router-link>
              <div class="text-muted small">
                <span>{{ event.status.name }}</span>
                &ndash;
                <span v-for="location in event.locations" :key="location.id">{{ location.name }}</span>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      query: "",
      events: [],
      loading: false,
      noResults: false
    }
  },

  methods: {
    ...mapActions(['apiGet']),
    doSearch() {
      this.loading = true;
      this.noResults = false;
      const query = encodeURIComponent(this.query)
      this.apiGet("events/"+ query).then((result) => {
        this.events = result.data.data;
        if(this.events.length === 0) {
          this.noResults = true;
        }
        this.loading = false;
      })
    },
    formatTime(date) {
      const dt = new Date(date)
      const minutes = (dt.getMinutes() < 10) ? "0"+ dt.getMinutes() : dt.getMinutes()
      const hours = (dt.getHours() < 10) ? "0"+ dt.getHours() : dt.getHours()
      return hours +":"+ minutes
    },
    formatDate(date) {
      const dt = new Date(date)
      return dt.getDate() +"-"+ (dt.getMonth() + 1) +"-"+ dt.getFullYear()
    },
    getWeekday(date) {
      const dt = new Date(date)
      const days = ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"]
      return days[dt.getDay()]
    }
  },

  mounted() {
    const q = this.$route.query.q;
    if(q != null && q !== "") {
      this.query = q;
      this.doSearch();
    }
  }
}
</script>